<template>
  <div class="page-accounting">
    <lead-center-navigation :overview="true" :monitoring="monitoring" />
    <div class="agGrid-page container">
      <base-header />
      <base-ag-grid
        :columnDefs="columnDefs"
        :cellValueChanged="cellValueChanged"
        :datasource="datasource"
        :firstDataRenderer="firstDataRenderer"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import LeadCenterCustomGroupRenderer from '@/agGridV2/renderers/lead-center-custom-group.renderer'
import LeadCenterManualQualifiedRenderer from '@/agGridV2/renderers/manual-qualified.renderer'
import LeadCenterApplicationsRenderer from '@/agGridV2/renderers/lead-center-applications.renderer'
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import DatePickRenderer from '@/agGridV2/renderers/date-pick.renderer'
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer.vue'

export default {
  components: {
    LeadCenterNavigation,
    BaseHeader,
    BaseAgGrid,

    // eslint-disable-next-line vue/no-unused-components
    LeadCenterCustomGroupRenderer,
    // eslint-disable-next-line vue/no-unused-components
    LeadCenterManualQualifiedRenderer,
    // eslint-disable-next-line vue/no-unused-components
    DatePickRenderer,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer,
    // eslint-disable-next-line vue/no-unused-components
    LeadCenterApplicationsRenderer,
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      cellValueChanged: null,
      monitoring: null,
      modules: null,
      gridApi: null
    }
  },
  async mounted() {
    this.$emitter.on('grid_api', (api) => {
      this.gridApi = api
    })
    this.modules = await MtApi.getModules()
    this.columnDefs = [
      {
        field: 'abbr_l',
        headerName: 'Abbr',
        width: 90
      },
      {
        field: 'automatic_lead_qualification_start',
        headerName: 'Setup Date',
        filter: 'agDateColumnFilter',
        cellRenderer: 'DatePickRenderer',
        cellStyle: { overflow: 'visible', padding: 0, margin: 0, border: 0 },
        width: 140
      },
      {
        field: 'setup',
        headerName: 'Active Platform',
        cellRenderer: 'CheckboxRenderer',
        width: 170
      },
      {
        field: 'url',
        headerName: 'URL',
        cellRenderer: (params) => {
          if (!params.value) {
            return ''
          }
          return `<a href="https://${params.value}" target="_blank">${params.value}</a>`
        },
      },
      {
        field: 'lead_apps',
        headerName: 'Apps Connected',
        cellRenderer: 'LeadCenterApplicationsRenderer',
        suppressSizeToFit: true,
        cellRendererParams: {
          modules: this.modules
        }
      },
      {
        field: 'gmp_apps',
        headerName: 'GMP Connected',
        cellRenderer: 'LeadCenterApplicationsRenderer',
        suppressSizeToFit: true,
        cellRendererParams: {
          modules: this.modules
        }
      },
      {
        field: 'ads_apps',
        headerName: 'Ads Connected',
        cellRenderer: 'LeadCenterApplicationsRenderer',
        suppressSizeToFit: true,
        cellRendererParams: {
          modules: this.modules
        }
      },
      {
        field: 'total_contacts',
        headerName: 'Total Contacts',
        filter: 'agNumberColumnFilter',
        width: 160
      },
      {
        field: 'last_7_days_contacts',
        headerName: 'Last 7 Days Contacts',
        filter: 'agNumberColumnFilter',
        width: 210
      },
      {
        field: 'total_activities',
        headerName: 'Total Activities',
        filter: 'agNumberColumnFilter',
        width: 160
      },
      {
        field: 'last_7_days_activities',
        headerName: 'Last 7 Days Activities',
        filter: 'agNumberColumnFilter',
        width: 210
      },
      {
        field: 'info',
        headerName: 'Info',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 2,
          cols: 30
        },
      },
      {
        field: 'services',
        headerName: 'Services',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 2,
          cols: 30
        },
      },
      {
        field: 'locations',
        headerName: 'Locations',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 2,
          cols: 30
        },
      },
      {
        field: 'average_check',
        headerName: 'ACV',
        headerTooltip: 'Average Check',
        filter: 'agNumberColumnFilter',
        editable: true,
        width: 90
      },
      {
        field: 'closing_rate_conversion_client',
        headerName: 'ACR (Conversion)',
        headerTooltip: 'Closing rate conversion client',
        filter: 'agNumberColumnFilter',
        editable: true,
        width: 190
      },
      {
        field: 'closing_rate_mql_client',
        headerName: 'ACR (MQL)',
        headerTooltip: 'Closing rate MQL client',
        filter: 'agNumberColumnFilter',
        editable: true,
        width: 140
      },
      {
        field: 'lead_center_note',
        headerName: 'Note',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 900 * 60,
          rows: 10,
          cols: 50
        }
      },
      {
        field: 'old_kpi',
        headerName: 'Old kpi',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 900 * 60,
          rows: 10,
          cols: 50
        }
      }
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
        MtApi.agGridLeadCenterOverview(params.request).then((response) => {
          this.monitoring = response.monitoring
          this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = (params) => {
      MtApi.updatePlatform(params.data.platform_id, {
        event: 'update_platform',
        field: {
          name: params.colDef.field,
          value: params.value
        }
      })
    }
  },
  methods: {
    firstDataRenderer() {
      this.gridApi.autoSizeColumns(['lead_apps', 'ads_apps', 'gbp_apps'])
    }
  }
}
</script>
