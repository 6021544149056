<template>
  <div v-if="application && module" class="application-settings-popup p-1">
    <div class="application-header mb-2">
      <div class="module-info">
        <div v-if="module.icon" class="icon"><img :src="module.icon" :alt="application.application_key"/></div>
        <div v-if="module.label" class="label"><strong>{{ module.label }}:</strong></div>
      </div>
      <div class="application-actions">
        <b-form class="sync-mode" @submit="syncApplication">
          <b-form-radio-group checked="last" name="mode">
            <b-form-radio value="last">Last</b-form-radio>
            <b-form-radio value="full">Full</b-form-radio>
          </b-form-radio-group>
          <b-button
            type="submit"
            :disabled="application.sync_status === 'process'"
            :variant="application.sync_status === 'failed' ? 'danger' : 'primary'"
            style="top: 10px; right: 10px"
            :class="[application.sync_status === 'process' ? 'disabled' : '']">
            {{ application.sync_status === 'process' ? 'Sync in process...' : application.sync_status === 'failed' ? 'Error' : 'Sync' }}
          </b-button>
        </b-form>
        <b-button
          variant="primary"
          style="top: 10px; right: 10px"
          @click="saveApplication()">
          <b-icon icon="save"></b-icon>
        </b-button>
      </div>
    </div>
    <div v-if="application.sync_error" class="error mb-2"><strong>Sync error:</strong> <span v-html="replaceErrorMessage(application.sync_error)"></span></div>
    <div v-if="application.sync_start" class="mb-2"><strong>Sync start:</strong> {{ application.sync_start }}</div>
    <div v-if="application.sync_end" class="mb-2"><strong>Sync end:</strong> {{ application.sync_end }}</div>
    <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
      <b-card class="bg-white rounded position-relative">
        <div class="application-settings">
          <div class="mb-2" v-for="(setting, index) in module.settings" :key="index">
            <b-row v-if="setting.type === 'Boolean'">
              <b-col>
                <label class="mb-1" :for="`input-${application.key}-${index}`">{{ setting.label }}:</label>
                <b-form-checkbox
                  :id="`input-${application.key}-${index}`"
                  class="active-checkbox"
                  name="check-button"
                  v-model="application.settings[setting.key]"
                  switch>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="setting.type === 'String'">
              <b-col>
                <label class="mb-1" :for="`input-${application.key}-${index}`"><strong>{{ setting.label }}:</strong></label>
                <b-form-input
                  :id="`input-${application.key}-${index}`"
                  style="border: 1px solid grey"
                  class="p-2"
                  v-model="application.settings[setting.key]"
                  type="text">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row v-if="setting.type === 'Radio'">
              <b-col>
                <label class="mb-1" :for="`input-${application.key}-${index}`"><strong>{{ setting.label }}:</strong></label>
                <b-form-radio-group v-model="application.settings[setting.key]" name="radio-group">
                  <b-form-radio v-for="option in setting.options" :key="option" :value="option">
                    {{ option }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>  
            </b-row>
            <b-row class="pt-1" v-if="setting.description">
              <b-col><div style="font-size: 10px;">{{ setting.description }}</div></b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import { showSnackbar } from '@/services'


export default {
  data() {
    return {
      loading: false,
      module: null,
      application: null,
      platform_id: null,
      intervalId: null
    }
  },
  methods: {
    async syncApplication(event) {
      event.preventDefault()
      const form = new FormData(event.target);
      const mode = form.get('mode');
      this.loading = true
      const response = await MtApi.syncApplication({
        platform: this.platform_id,
        _id: this.application._id,
        mode
      });
      if (response?.message) {
        showSnackbar({
          text: response.message,
          mode: 'danger'
        })
      }
      await this.updateStatuses();
      this.loading = false
    },
    async saveApplication() {
      this.loading = true
      const platform = await MtApi.getPlatform(this.platform_id, { select: 'new_applications' })
      const filteredApplicationIndex = platform.new_applications.findIndex((na) => na._id === this.application._id)
      const data = { key: this.application.key, index: filteredApplicationIndex, type: platform.new_applications[filteredApplicationIndex].type }
      await MtApi.updatePlatform(this.platform_id, {
        event: 'update_new_applications',
        field: {
          value: {
            data,
            settings: this.application.settings
          }
        }
      })
      showSnackbar({
        text: 'Saved!',
        mode: 'success'
      })
      this.loading = false
    },
    updateStatuses() {
      (async () => {
        const platform_applications = await MtApi.getPlatform(this.platform_id, { select: 'new_applications' })
        if (platform_applications.new_applications) {
          const filteredApplication = platform_applications.new_applications.find((na) => na._id === this.application._id)
          if (filteredApplication) {
            this.application = JSON.parse(JSON.stringify({
              ...this.application,
              last_sync_date: filteredApplication?.last_sync_date,
              sync_start: filteredApplication?.sync_start,
              sync_status: filteredApplication?.sync_status,
              sync_error: filteredApplication?.sync_error
            }))
          }
        }
      })()
    },
    replaceErrorMessage(message) {
      return SimpleHelper.replaceBase64WithImgTag(message);
    }
  },
  mounted() {
    this.module = this.$attrs.module
    this.application = this.$attrs.application
    this.platform_id = this.$attrs.platform_id
    this.intervalId = setInterval(this.updateStatuses, 5000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
</script>
<style lang="scss" scoped>
.application-settings-popup {
  margin: 25px;
  overflow: hidden;
  .icon {
    img {
      height: 25px;
      width: 25px;
    }
  }
  .label {
    text-decoration: underline;
    font-size: large;
  }
  .error {
    background-color: #fee6e6;
    border: 1px solid #ddd;
    padding: 10px;
    word-wrap: break-word;
    min-height: 425px;
    ::v-deep {
      img {
        height: calc(100% - 25px);
        object-fit: contain;
        width: 100%;
      }
    }
  }
  .application-actions {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
  .application-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sync-mode {
    display: flex;
    align-items: center
  }
  button {
    border-radius: 0;
  }
  .module-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
